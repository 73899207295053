import React, { useEffect, useState } from "react";
import HomeContent from "./HomeContent.js";
import FirstScreen from "../components/FirstScreen.js";

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  return <div>{isLoading ? <FirstScreen /> : <HomeContent />}</div>;
};

export default Home;
