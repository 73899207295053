import React, { useEffect, useState } from "react";
import logo from "../assets/logo/tebakjodoh.png";
import { Image } from "react-bulma-components";
import SplashScreen from "../components/SplashScreen";
import Footer from "../components/Footer.js";
import { Link } from "react-router-dom";

const HomeContent = () => {
  const [name, setName] = useState("random");
  const [umur, setUmur] = useState("random");
  const [jenisKelamin, setJenisKelamin] = useState("pria");
  const [hobi, setHobi] = useState("random");
  const [user, setUser] = useState("random");

  const style = { backgroundColor: "#ffaec9" };
  const buttonColor = {
    backgroundColor: "#da7e88",
    color: "white",
    borderRadius: "1vh",
    width: "100%",
    height: "6vh",
    fontSize: "18px",
  };

  const inputStyle = { borderRadius: "1vh" };

  useEffect(() => {
    setUser({
      name: name,
      jenisKelamin: jenisKelamin,
      umur: umur,
      hobi: hobi,
    });
  }, [name, jenisKelamin, umur, hobi]);

  return (
    <div>
      <div className="hero is-fullheight is-light " style={style}>
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-half">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#ffaec9",
                  }}
                >
                  <Image src={logo} alt="Logo" style={{ maxWidth: "30vh" }} />
                </div>

                <div className="field">
                  <label className="label has-text-white">Nama</label>
                  <div className="control">
                    <input
                      className="input is-danger"
                      type="text"
                      style={inputStyle}
                      onInput={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label has-text-white">Jenis Kelamin</label>
                  <div className="control">
                    <div className="select is-danger" style={{ width: "100%" }}>
                      <select
                        style={{ width: "100%" }}
                        onChange={(e) => setJenisKelamin(e.target.value)}
                      >
                        <option>jenis kelamin</option>
                        <option>Pria</option>
                        <option>Wanita</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label has-text-white">Umur</label>
                  <div className="control">
                    <input
                      className="input is-danger"
                      type="text"
                      style={inputStyle}
                      onInput={(e) => setUmur(e.target.value)}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label has-text-white">Hobi</label>
                  <div className="control">
                    <textarea
                      className="textarea is-danger"
                      style={inputStyle}
                      onInput={(e) => setHobi(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <br />
                <div className="control has-text-centered">
                  <Link to={{ pathname: "/result" }} state={{ user: user }}>
                    <button className="button is-large" style={buttonColor}>
                      Mulai
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default HomeContent;
