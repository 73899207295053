import React from "react";
import logo from "../assets/logo/tebakjodoh.png";
import { Image } from "react-bulma-components";
import { SyncLoader } from "react-spinners";
const FirstScreen = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#ffaec9",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Image src={logo} alt="Logo" size="128x128" />
        <h3 style={{ color: "white", fontWeight: "bold" }}>Tebak Jodoh</h3>
      </div>
    </div>
  );
};

export default FirstScreen;
