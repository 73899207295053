import React from "react";

const Footer = () => {
  const footerStyle = {
    color: "white",
    fontSize: "20px",
    textAlign: "center",
    fontWeight: "bold",
  };
  const footerSubStyle = {
    color: "white",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: "bold",
  };
  return (
    <footer
      className="is-centered"
      style={({ backgroundColor: "transparent" }, { paddingBottom: "20px" })}
    >
      <h1 style={footerStyle}>tebak jodoh</h1>
      <h1 className="has-text-white" style={footerSubStyle}>
        v1.0
      </h1>
    </footer>
  );
};

export default Footer;
