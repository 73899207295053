import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import SplashScreen from "../components/SplashScreen.js";

const ResultContent = () => {
  const location = useLocation();
  const { user } = location.state;
  const [namaJodoh, setNamaJodoh] = useState("value");
  const [kriteriaJodoh, setKriteriaJodoh] = useState("value");
  const [tanggalNikah, setTanggalNikah] = useState("value");
  const [pekerjaanJodoh, setPekerjaanJodoh] = useState("value");
  const [namaAnak, setNamaAnak] = useState("value");
  const [hasil, setHasil] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const getRamal = async () => {
    setIsLoading(true);
    const resultNamaJodoh = await axios.post(
      "https://tebak-jodoh.aditiyak.site/nama-jodoh",
      {
        nama: user.name,
        jenis_kelamin: user.jenisKelamin,
        umur: user.umur,
        hobi: user.hobi,
      }
    );
    setNamaJodoh(resultNamaJodoh.data.data);
    setHasil(resultNamaJodoh.data.data);
    const resultKriteria = await axios.post(
      "https://tebak-jodoh.aditiyak.site/kriteria-jodoh",
      {
        nama: user.name,
        jenis_kelamin: user.jenisKelamin,
        umur: user.umur,
        hobi: user.hobi,
      }
    );
    setKriteriaJodoh(resultKriteria.data.data);

    const resultTanggalNikah = await axios.post(
      "https://tebak-jodoh.aditiyak.site/tanggal-nikah",
      {
        nama: user.name,
        jenis_kelamin: user.jenisKelamin,
        umur: user.umur,
        hobi: user.hobi,
      }
    );
    setTanggalNikah(resultTanggalNikah.data.data);

    const resultPekerjaanJodoh = await axios.post(
      "https://tebak-jodoh.aditiyak.site/pekerjaan-jodoh",
      {
        nama: user.name,
        jenis_kelamin: user.jenisKelamin,
        umur: user.umur,
        hobi: user.hobi,
      }
    );
    setPekerjaanJodoh(resultPekerjaanJodoh.data.data);

    const resultNamaAnak = await axios.post(
      "https://tebak-jodoh.aditiyak.site/nama-anak",
      {
        nama: user.name,
        jenis_kelamin: user.jenisKelamin,
        umur: user.umur,
        hobi: user.hobi,
      }
    );
    setNamaAnak(resultNamaAnak.data.data);

    setIsLoading(false);
  };

  const getNamaJodoh = () => {
    setHasil(namaJodoh);
  };
  const getKriteriaJodoh = () => {
    setHasil(kriteriaJodoh);
  };
  const getTanggalNikah = () => {
    setHasil(tanggalNikah);
  };
  const getPekerjaanJodoh = () => {
    setHasil(pekerjaanJodoh);
  };
  const getNamaAnak = () => {
    setHasil(namaAnak);
  };
  const style = { backgroundColor: "#ffaec9" };
  const buttonColor = {
    backgroundColor: "#da7e88",
    color: "white",
    borderRadius: "1vh",
    fontSize: "18px",
    width: "18vh",
  };

  const buttonChooseStyle = {
    backgroundColor: "white",
    color: "black",
    borderRadius: "1vh",
    width: "100%",
    height: "auto",
    fontSize: "18px",
    borderColor: "red",
    textAlign: "left",
  };

  const inputStyle = { borderRadius: "1vh" };
  const resultStyle = { borderRadius: "1vh", height: "30vh" };
  const titleStyle = {
    color: "white",
    fontSize: "30px",
    textAlign: "center",
    fontWeight: "bold",
  };

  const menus = [
    {
      id: 1,
      title: "Nama Jodoh",
      subtitle: "Tebak Nama Jodohmu",
      fungsi: "getNamaJodoh",
    },
    {
      id: 2,
      title: "Kriteria Jodoh",
      subtitle: "Tebak Kriteria Jodohmu",
      fungsi: "getKriteriaJodoh",
    },
    {
      id: 3,
      title: "Pekerjaan Jodoh",
      subtitle: "Tebak Pekerjaan Jodohmu",
      fungsi: "getPekerjaanJodoh",
    },
    {
      id: 4,
      title: "Tanggal Nikah",
      subtitle: "Tebak Tanggal Nikahmu",
      fungsi: "getTanggalNikah",
    },
    {
      id: 5,
      title: "Nama Anak",
      subtitle: "Tebak Nama Anakmu",
      fungsi: "getNamaAnak",
    },
  ];
  const reload = () => {
    window.location.reload();
  };
  const handleMenuClick = (fungsi) => {
    // Memanggil fungsi yang sesuai berdasarkan nilai string fungsi
    switch (fungsi) {
      case "getNamaJodoh":
        getNamaJodoh();
        break;
      case "getKriteriaJodoh":
        getKriteriaJodoh();
        break;
      case "getPekerjaanJodoh":
        getPekerjaanJodoh();
        break;
      case "getTanggalNikah":
        getTanggalNikah();
        break;
      case "getNamaAnak":
        getNamaAnak();
        break;
      default:
        break;
    }
  };
  const buttonMenus = menus.map((menu) => (
    <div className="row pb-4" key={menu.id}>
      <button
        className="button is-fullwidth"
        style={buttonChooseStyle}
        onClick={() => handleMenuClick(menu.fungsi)}
      >
        <div className="has-text-left">
          <h2 className="title is-3 ">{menu.title}</h2>
          <h3 className="subtitle is-5">{menu.subtitle}</h3>
        </div>
      </button>
    </div>
  ));
  useEffect(() => {
    getRamal();
  }, []);

  return (
    <div>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <div className="hero is-fullheight is-light " style={style}>
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-half">
                  <h1 className="is-centered" style={titleStyle}>
                    Tebak Jodoh
                  </h1>
                  <br />
                  <div className="field">
                    <div className="control">
                      <textarea
                        className="textarea is-danger"
                        style={resultStyle}
                        value={hasil}
                        readOnly
                      ></textarea>
                    </div>
                  </div>
                  <br />
                  <div className="buttons is-centered is-fullwidth">
                    <Link to={{ pathname: "/" }} state={{ loading: false }}>
                      {" "}
                      <button className="button " style={buttonColor}>
                        Kembali
                      </button>
                    </Link>
                    <button
                      className="button "
                      style={buttonColor}
                      onClick={reload}
                    >
                      Coba Lagi
                    </button>
                  </div>
                  <br />
                  <div className="rows is-centered">{buttonMenus}</div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default ResultContent;
