import React, { useEffect, useState } from "react";
import logo from "../assets/logo/tebakjodoh.png";
import { Image } from "react-bulma-components";
import { SyncLoader } from "react-spinners";
const SplashScreen = () => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [textRamal, setTextRamal] = useState("");
  const simulateAPIRequest = () => {
    // Mengatur progres loading secara periodik
    const interval = setInterval(() => {
      setLoadingProgress((prevProgress) => {
        // Menambah progres loading sebesar 10%
        const newProgress = prevProgress + 0.7;

        // Berhenti ketika progres mencapai 100%
        if (newProgress >= 1) {
          setTextRamal("saya sedang meramal nama jodoh anda...");
        }

        if (newProgress >= 15) {
          setTextRamal("saya sedang meramal kriteria jodoh anda...");
        }

        if (newProgress >= 35) {
          setTextRamal("saya sedang meramal tanggal pernikahan anda...");
        }

        if (newProgress >= 55) {
          setTextRamal("saya sedang meramal pekerjaan jodoh anda...");
        }
        if (newProgress >= 75) {
          setTextRamal("saya sedang meramal nama anak anda...");
        }

        if (newProgress >= 85) {
          setTextRamal("tunggu sebentar saya sedang merangkumnya...");
        }

        if (newProgress >= 100) {
          clearInterval(interval);
        }

        return newProgress;
      });
    }, 400); // Interval waktu pembaruan progres (dalam milidetik)

    // Simulasi pemanggilan API
    setTimeout(() => {
      // Proses pemanggilan API selesai di sini
    }, 40000); // Simulasi waktu pemanggilan API (dalam milidetik)
  };
  useEffect(() => {
    simulateAPIRequest();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#ffaec9",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Image src={logo} alt="Logo" size="128x128" />
        <SyncLoader
          color={"white"}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        <br />
        <style>
          {`
          .custom-progress::-webkit-progress-value {
            background-color: #da7e88; 
          }
        `}
        </style>
        <progress
          className="progress custom-progress"
          value={loadingProgress}
          max="100"
          style={{ width: "30vh" }}
        >
          {loadingProgress}
        </progress>
        <br />
        <span style={{ width: "30vh" }} className="has-text-white">
          {textRamal}
        </span>
      </div>
    </div>
  );
};

export default SplashScreen;
