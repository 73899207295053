import React, { useEffect, useState } from "react";
import SplashScreen from "../components/SplashScreen.js";
import ResultContent from "./ResultContent.js";
const Result = () => {
  const [isLoading, setIsLoading] = useState(false);

  return <div>{isLoading ? <SplashScreen /> : <ResultContent />}</div>;
};

export default Result;
